<template>
    <Header />
    <div class="container container-width">
        <h2>No Access</h2>
        <p class="lead text-start">
            Your account does not have permission to view this page.
        </p>
        <p class="text-muted">
            Please contact your Project Manager if you have any questions.
        </p>
    </div>
</template>

<script>
import Header from '../../shared/navigation/Header.vue';
export default {
    name: 'NoAccess',
    components: {
        Header
    },
    mounted() {
        this.$router.replace({ path: this.$store.getters.landingPage });
    }
};
</script>

<style>
.container{
    margin-top:6rem;
}
</style>
